<template lang="pug">
.main-wrapper.endpoints-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Histórico de Integrações</b>

    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
        .p-grid.p-fluid
            .p-col-3
                label.form-label Data inicial:
                Calendar( 
                    v-model='filters.data_criacao_gte' 
                    dateFormat="dd/mm/yy" 
                    :locale="ptbr"
                    :manualInput='false'
                    showIcon 
                    iconDisplay="input"
                    placeholder="Data inicial"
                    @input='applyFilters()'
                )
            .p-col-3
                label.form-label Data Final:
                Calendar( 
                    v-model='filters.data_criacao_lte' 
                    dateFormat="dd/mm/yy" 
                    :locale="ptbr"
                    :manualInput='false'
                    showIcon 
                    iconDisplay="input"
                    placeholder="Data final"
                    @input='applyFilters()'
                )
            .p-col-3
                label.form-label Tipo de Histórico:
                Dropdown( 
                    :options='options.ie_tipo_historico'
                    placeholder='TODOS'
                    optionLabel='label'
                    optionValue='value'
                    dataKey='value'
                    v-model='filters.ie_tipo_historico'
                    @change='applyFilters()'
                )
            .p-col-3
                label.form-label Serviço:
                Dropdown( 
                    :options='options.servicos'
                    placeholder='TODOS'
                    optionLabel='label'
                    optionValue='value'
                    dataKey='value'
                    v-model='filters.cd_sistema'
                    @change='applyFilters()'
                )
            
                    
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        Panel.datatable(header='Lista de Integrações')
            DataTable(:value="list")
                Column(headerStyle='width: 25%;' field='ds_descricao' header='Descrição')
                Column(headerStyle='width: 25%;' field='ds_mensagem' header='Mensagem')
                Column(headerStyle='width: 25%;' field='ds_endpoint' header='Endpoint')
                Column(headerStyle='width: 10%;' field='nr_status_code' header='Status')
                Column(headerStyle='width: 15%;' field='ie_tipo_historico' header='Tipo de Integração')
                    template(#body='props')
                        div(v-if='props.data.ie_tipo_historico') Envio 
                        div(v-else) Recebimento 
                Column(headerStyle='width: 20%;' field='dt_criado' header='Data da integração')
                    template(#body='props')
                        div {{ formatDate(props.data.dt_criado) }}
                Column(headerStyle='width: 8%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Detalhes'"
                                icon="jam jam-write"
                                @click="$router.push(`/historico-integracao/listar/${ props.data.id }/`)"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
    </template>
    
    <script>
        import ProgressBar from 'primevue/progressbar'
        import DataView from 'primevue/dataview'
        import Panel from 'primevue/panel'
        import Paginator from 'primevue/paginator'
        import DataTable from 'primevue/datatable'
        import Column from 'primevue/column'
        import Button from 'primevue/button'
        import Tooltip from 'primevue/tooltip'
        import Dialog from 'primevue/dialog'
        import InputText from 'primevue/inputtext'
        import ProgressSpinner from 'primevue/progressspinner'
        import Calendar from 'primevue/calendar'
        import Dropdown from 'primevue/dropdown'

        import {formatDateToBR, pCalendarLocale_ptbr} from '@/utils.js'
        import { HistoricoIntegracao, ServicosExternos } from './../../middleware'
        import wsConfigs from './../../middleware/configs'
        import moment from 'moment'

        export default {
            created () { 
                this.applyFilters() 
                let params = {
                    paginacao: false
                }
                this.getServicosExternos(params)
            },
            components: { ProgressBar, DataView, Panel, Paginator, DataTable,
                Column, Button, Tooltip, Dialog, ProgressSpinner, InputText,
                formatDateToBR, Dropdown, Calendar, pCalendarLocale_ptbr},
            directives: { tooltip: Tooltip },
            data () {
                return {
                    list: [],
                    windowInnerWidth: window.innerWidth,
                    waiting: false,
                    ptbr: pCalendarLocale_ptbr,
                    filters: {
                        data_criacao_gte: '',
                        data_criacao_lte: '',
                        cd_sistema: '',
                        ie_tipo_historico: '1'
                    },
                    options: {
                        ie_tipo_historico: [
                            {value: null, label: 'TODOS'},
                            {value: '0', label: 'Recebimento'},
                            {value: '1', label: 'Envio'},
                        ],
                        servicos: []
                    },
                    paginator: {
                        page: this.$route.query.pg ? this.$route.query.pg : 1,
                        per_page: wsConfigs.paginator_perPage,
                        count: 0
                    }
                }
            },
            methods: {
                getList (params) {
                    this.waiting = true
                    return HistoricoIntegracao.findAll(params).then(response => {
                        if (response.status == 200) {
                            this.paginator.count = response.data.count
                            this.list = response.data.results
                        }else {
                            this.$toast.error("Erro ao realizar a busca por logs de integração.")
                            if (response.data.message){
                                for (let i = 0; i < response.data.message.length; i++){
                                    this.$toast.error(response.data.message[i])
                                }
                                Object.keys(response.data.message).forEach((key) => {
                                    this.$toast.error(`${key} - ${response.data.message[key]}`)
                                })
                            }else{
                                this.$toast.error(response.data.detail)
                            }
                        }

                        this.waiting = false
                        return true
                    })
                },
                applyFilters () {
                    let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                    if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                    Object.keys(this.filters).forEach((key) => { 
                        if (key.substr(0, 5) == 'data_'){
                            if (this.filters[key]){
                                params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                            } 
                        }else if (this.filters[key]){
                            params[key] = this.filters[key]
                        }   
                    })
                    this.getList(params)
                },
                getServicosExternos(params){
                    ServicosExternos.findAll(params).then(response => {
                        if (response.status === 200){
                            if (response.data){
                                response.data.forEach(servico => {
                                    this.options.servicos.push({ value: servico.id, label: servico.nm_nome, textAlt: servico.nm_nome })
                                })
                            }
                        }
                    })
                },
                onPage (ev) {
                    this.paginator.page = ev.page + 1
                    this.applyFilters()
                },
                formatDate(dt){
                    return formatDateToBR(dt)
                },
                clearFilters () {
                    Object.keys(this.filters).forEach(k => {
                        this.filters[k] = null
                    })
                    this.applyFilters()
                },

    
            }
        }
    </script>